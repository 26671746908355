import Vue from 'vue'
import * as types from '../mutation-types'
import { simulationService } from '@/services'

const state = {
  simulation: null,
  simulations: [],
  simulationTeam: null,
  simulationTeams: [],
  simulationUsers: []
}

const getters = {
  currentSimulation: (state) => state.simulation,
  currentSimulationTeam: (state) => state.simulationTeam,
  simulations: (state) => state.simulations,
  simulationTeams: (state) => state.simulationTeams,
  simulationUsers: (state) => state.simulationUsers
}

const mutations = {
  [types.ADD_SIMULATION_SUCCESS](state, payload) {
    const simulations = state.simulations
    const index = simulations.findIndex((simulation) => simulation.id === payload.id)
    if (index === -1) {
      const simulation = {
        avatar: payload.avatar,
        client: payload.client,
        code: payload.code,
        endDate: payload.end_date,
        facilitators: payload.facilitators,
        id: payload.id,
        location: payload.location,
        name: payload.name,
        participants: payload.participants,
        roleId: payload.role,
        startDate: payload.start_date,
        status: payload.status,
        statusName: payload.status_name
      }

      simulations.push(simulation)
    }
  },
  [types.ADD_SIMULATION_TEAM_SUCCESS](state, payload) {
    const teams = state.simulationTeams
    const index = teams.findIndex((team) => team.id === payload.id)
    if (index === -1) {
      const team = {
        avatar: payload.avatar,
        id: payload.id,
        simulationId: payload.simulation_id,
        name: payload.team_name,
        teamId: payload.team_id
      }

      teams.push(team)
    }
  },
  [types.DELETE_SIMULATION_SUCCESS](state, payload) {
    const simulations = state.simulations
    const index = simulations.findIndex((simulation) => simulation.id === payload)
    if (index !== -1) {
      Vue.delete(simulations, index)
    }
  },
  [types.DELETE_SIMULATION_TEAM_SUCCESS](state, payload) {
    const teams = state.simulationTeams
    const index = teams.findIndex((team) => team.teamId === payload)
    if (index !== -1) {
      Vue.delete(teams, index)
    }
  },
  [types.DELETE_SIMULATION_USER_SUCCESS](state, payload) {
    const users = state.simulationUsers
    const index = users.findIndex((user) => user.id === payload)
    if (index !== -1) {
      Vue.delete(users, index)
    }
  },
  [types.FETCH_SIMULATIONS_SUCCESS](state, payload) {
    const simulations = payload.map((simulation) => {
      return {
        avatar: simulation.avatar,
        client: simulation.client,
        code: simulation.code,
        endDate: simulation.end_date,
        facilitators: simulation.facilitators,
        id: simulation.id,
        location: simulation.location,
        name: simulation.name,
        participants: simulation.participants,
        roleId: simulation.role,
        startDate: simulation.start_date,
        status: simulation.status,
        statusName: simulation.status_name
      }
    })

    state.simulations = simulations
  },
  [types.FETCH_SIMULATION_SUCCESS](state, payload) {
    const simulation = {
      avatar: payload.avatar,
      client: payload.client,
      code: payload.code,
      endDate: payload.end_date,
      facilitators: payload.facilitators,
      id: payload.id,
      location: payload.location,
      name: payload.name,
      participants: payload.participants,
      roleId: payload.role,
      startDate: payload.start_date,
      status: payload.status,
      statusName: payload.status_name
    }

    state.simulation = simulation
  },
  [types.FETCH_SIMULATION_TEAMS_SUCCESS](state, payload) {
    const teams = payload.map((team) => {
      return {
        avatar: team.avatar,
        id: team.id,
        simulationId: team.simulation_id,
        name: team.team_name,
        teamId: team.team_id
      }
    })
    state.simulationTeams = teams
  },
  [types.FETCH_SIMULATION_USERS_SUCCESS](state, payload) {
    const users = payload.map((user) => {
      return {
        accountRoleId: user.account_role_id,
        accountRoleName: user.account_role_name,
        active: user.active,
        avatar: user.avatar,
        email: user.email,
        firstName: user.first_name,
        id: user.id,
        lastName: user.last_name,
        name: user.full_name,
        simulationRoleName:
          user.simulations && user.simulations.length
            ? user.simulations[0].simulation_role_name
            : '',
        simulations: user.simulations.map((sim) => {
          return {
            id: sim.id,
            simulationRoleId: sim.simulation_role_id,
            simulationRoleName: sim.simulation_role_name,
            simulationTeamId: sim.simulation_team_id,
            simulationTeamName: sim.simulation_team_name
          }
        }),
        simulationTeamName:
          user.simulations && user.simulations.length
            ? user.simulations[0].simulation_team_name
            : '',
        status: user.status
      }
    })

    state.simulationUsers = users
  },
  [types.UPDATE_SIMULATION_SUCCESS](state, payload) {
    const simulations = state.simulations
    const index = simulations.findIndex((simulation) => simulation.id === payload.id)
    if (index !== -1) {
      const simulation = {
        avatar: payload.avatar,
        client: payload.client,
        code: payload.code,
        endDate: payload.end_date,
        facilitators: payload.facilitators,
        id: payload.id,
        location: payload.location,
        name: payload.name,
        participants: payload.participants,
        roleId: payload.role,
        startDate: payload.start_date,
        status: payload.status,
        statusName: payload.status_name
      }

      Vue.set(simulations, index, simulation)
    }
  },
  [types.UPDATE_SIMULATION_TEAM_SUCCESS](state, payload) {
    const teams = state.simulationTeams
    const index = teams.findIndex((team) => team.id === payload.id)
    if (index !== -1) {
      const team = {
        avatar: payload.avatar,
        id: payload.id,
        simulationId: payload.simulation_id,
        name: payload.team_name,
        teamId: payload.team_id
      }

      Vue.set(teams, index, team)
    }
  },
  [types.UPSERT_SIMULATION_USERS_SUCCESS](state, payload) {
    const users = state.simulationUsers
    const index = users.findIndex((user) => user.id === payload.id)
    if (index !== -1) {
      const user = {
        accountRoleId: payload.account_role_id,
        active: payload.active,
        avatar: payload.avatar,
        email: payload.email,
        firstName: payload.first_name,
        id: payload.id,
        lastName: payload.last_name,
        name: payload.full_name,
        simulations: payload.simulations.map((sim) => {
          return {
            id: sim.id,
            simulationRoleId: sim.simulation_role_id,
            simulationTeamId: sim.simulation_team_id
          }
        }),
        status: payload.status
      }
    }
  },
  resetSimulationTeams(state) {
    state.simulationTeams = []
  },
  setCurrentSimulation(state, payload) {
    state.simulation = payload
  },
  setCurrentSimulationTeam(state, payload) {
    state.simulationTeam = payload
  }
}

const actions = {
  async addSimulation({ commit, dispatch }, data) {
    try {
      await commit('loading/SET_SIMULATION_PROCESSING', true, { root: true })
      const { data: response } = await simulationService.addSimulation(data)
      await commit(types.ADD_SIMULATION_SUCCESS, response.data)
      await commit('loading/SET_SIMULATION_PROCESSING', false, { root: true })
      const notification = {
        type: 'success',
        title: 'Simulation added',
        message: `${response.data.name} has been added successfully`
      }
      await dispatch('notification/addNotification', notification, { root: true })
    } catch (error) {
      await commit('loading/SET_SIMULATION_PROCESSING', false, { root: true })
      const notification = {
        type: 'error',
        title: 'Failed to retrive current day',
        message: error.response.data.message
      }
      await dispatch('notification/addNotification', notification, { root: true })
    }
  },
  async addSimulationTeam({ commit, dispatch }, { id, payload }) {
    try {
      await commit('loading/SET_SIMULATION_TEAM_PROCESSING', true, { root: true })
      const { data: response } = await simulationService.addSimulationTeam(id, payload)
      await commit(types.ADD_SIMULATION_TEAM_SUCCESS, response.data)
      await commit('loading/SET_SIMULATION_TEAM_PROCESSING', false, { root: true })
      const notification = {
        type: 'success',
        title: 'Simulation team added',
        message: `${response.data.team_name} has been added successfully`
      }
      await dispatch('notification/addNotification', notification, { root: true })
    } catch (error) {
      await commit('loading/SET_SIMULATION_TEAM_PROCESSING', false, { root: true })
      const notification = {
        type: 'error',
        title: 'Failed to add simulation team',
        message: error.response.data.message
      }
      await dispatch('notification/addNotification', notification, { root: true })
    }
  },
  async deleteSimulation({ commit, dispatch }, id) {
    try {
      await commit('loading/SET_SIMULATION_PROCESSING', true, { root: true })
      await simulationService.deleteSimulation(id)
      await commit(types.DELETE_SIMULATION_SUCCESS, id)
      await commit('loading/SET_SIMULATION_PROCESSING', false, { root: true })
      const notification = {
        type: 'success',
        title: 'Simulation deleted',
        message: 'Simulation has been deleted successfully'
      }
      await dispatch('notification/addNotification', notification, { root: true })
    } catch (error) {
      await commit('loading/SET_SIMULATION_PROCESSING', false, { root: true })
      const notification = {
        type: 'error',
        title: 'Failed to delete simulation',
        message: error.response.data.message
      }
      await dispatch('notification/addNotification', notification, { root: true })
    }
  },
  async deleteSimulationTeam({ commit, dispatch }, { id, teamId }) {
    try {
      await commit('loading/SET_SIMULATION_TEAM_PROCESSING', true, { root: true })
      await simulationService.deleteSimulationTeam(id, { team_id: teamId })
      await commit(types.DELETE_SIMULATION_TEAM_SUCCESS, teamId)
      await commit('loading/SET_SIMULATION_TEAM_PROCESSING', false, { root: true })
      const notification = {
        type: 'success',
        title: 'Simulation team deleted',
        message: 'Simulation team has been deleted successfully'
      }
      await dispatch('notification/addNotification', notification, { root: true })
    } catch (error) {
      await commit('loading/SET_SIMULATION_TEAM_PROCESSING', false, { root: true })
      const notification = {
        type: 'error',
        title: 'Failed to delete simulation team',
        message: error.response.data.message
      }
      await dispatch('notification/addNotification', notification, { root: true })
    }
  },
  async deleteSimulationUser({ commit, dispatch }, { id, userId }) {
    try {
      await commit('loading/SET_SIMULATION_USER_PROCESSING', true, { root: true })
      await simulationService.deleteSimulationUser(id, { user_id: userId })
      await commit(types.DELETE_SIMULATION_USER_SUCCESS, userId)
      await commit('loading/SET_SIMULATION_USER_PROCESSING', false, { root: true })
      const notification = {
        type: 'success',
        title: 'Simulation user deleted',
        message: 'Simulation user has been deleted successfully'
      }
      await dispatch('notification/addNotification', notification, { root: true })
    } catch (error) {
      await commit('loading/SET_SIMULATION_USER_PROCESSING', false, { root: true })
      const notification = {
        type: 'error',
        title: 'Failed to delete simulation user',
        message: error.response.data.message
      }
      await dispatch('notification/addNotification', notification, { root: true })
    }
  },
  async fetchSimulations({ commit, dispatch }) {
    try {
      await commit('loading/SET_SIMULATIONS_LOADING', true, { root: true })
      const { data } = await simulationService.getSimulations()
      await commit(types.FETCH_SIMULATIONS_SUCCESS, data.data)
      await commit('loading/SET_SIMULATIONS_LOADING', false, { root: true })
    } catch (error) {
      await commit('loading/SET_SIMULATIONS_LOADING', false, { root: true })
      const notification = {
        type: 'error',
        title: 'Failed to fetch simulations',
        message: error.response.data.message
      }
      await dispatch('notification/addNotification', notification, { root: true })
    }
  },
  async fetchSimulationByCode({ commit, dispatch }, code) {
    try {
      await commit('loading/SET_SIMULATION_LOADING', true, { root: true })
      const { data } = await simulationService.getSimulationByCode(code)
      await commit(types.FETCH_SIMULATION_SUCCESS, data.data)
      await commit('loading/SET_SIMULATION_LOADING', false, { root: true })
    } catch (error) {
      await commit('loading/SET_SIMULATION_LOADING', false, { root: true })
      const notification = {
        type: 'error',
        title: 'Failed to fetch simulation',
        message: error.response.data.message
      }
      await dispatch('notification/addNotification', notification, { root: true })
    }
  },
  async fetchSimulationById({ commit, dispatch }, id) {
    try {
      await commit('loading/SET_SIMULATION_LOADING', true, { root: true })
      const { data } = await simulationService.getSimulationById(id)
      await commit(types.FETCH_SIMULATION_SUCCESS, data.data)
      await commit('loading/SET_SIMULATION_LOADING', false, { root: true })
    } catch (error) {
      await commit('loading/SET_SIMULATION_LOADING', false, { root: true })
      const notification = {
        type: 'error',
        title: 'Failed to fetch simulation',
        message: error.response.data.message
      }
      await dispatch('notification/addNotification', notification, { root: true })
    }
  },
  async fetchSimulationTeams({ commit, dispatch }, id) {
    try {
      await commit('loading/SET_SIMULATION_TEAMS_LOADING', true, { root: true })
      const { data } = await simulationService.getSimulationTeams(id)
      commit(types.FETCH_SIMULATION_TEAMS_SUCCESS, data.data)
      await commit('loading/SET_SIMULATION_TEAMS_LOADING', false, { root: true })
    } catch (error) {
      console.log(error)
      await commit('loading/SET_SIMULATION_TEAMS_LOADING', false, { root: true })
      // const notification = {
      //   type: 'error',
      //   title: 'Failed to fetch simulation teams',
      //   message: 'Failed to fetch simulation teams'
      // }
      // await dispatch('notification/addNotification', notification, { root: true })
    }
  },
  async fetchSimulationUsers({ commit, dispatch }, id) {
    try {
      await commit('loading/SET_SIMULATION_USERS_LOADING', true, { root: true })
      const { data } = await simulationService.getSimulationUsers(id)
      commit(types.FETCH_SIMULATION_USERS_SUCCESS, data.data)
      await commit('loading/SET_SIMULATION_USERS_LOADING', false, { root: true })
    } catch (error) {
      await commit('loading/SET_SIMULATION_USERS_LOADING', false, { root: true })
      const notification = {
        type: 'error',
        title: 'Failed to fetch simulation users',
        message: error.response.data.message
      }
      await dispatch('notification/addNotification', notification, { root: true })
    }
  },
  async updateSimulation({ commit, dispatch }, { id, payload }) {
    try {
      await commit('loading/SET_SIMULATION_PROCESSING', true, { root: true })
      const { data } = await simulationService.updateSimulation(id, payload)
      await commit(types.UPDATE_SIMULATION_SUCCESS, data)
      await commit('loading/SET_SIMULATION_PROCESSING', false, { root: true })
      const notification = {
        type: 'success',
        title: 'Simulation updated',
        message: `${data.name} has been updated successfully`
      }
      await dispatch('notification/addNotification', notification, { root: true })
    } catch (error) {
      await commit('loading/SET_SIMULATION_PROCESSING', false, { root: true })
      const notification = {
        type: 'error',
        title: 'Failed to update simulation',
        message: error.response.data.message
      }
      await dispatch('notification/addNotification', notification, { root: true })
    }
  },
  async updateSimulationTeam({ commit, dispatch }, { id, payload }) {
    try {
      await commit('loading/SET_SIMULATION_TEAM_PROCESSING', true, { root: true })
      const { data } = await simulationService.updateSimulationTeam(id, payload)
      await commit(types.UPDATE_SIMULATION_TEAM_SUCCESS, data.data)
      await commit('loading/SET_SIMULATION_TEAM_PROCESSING', false, { root: true })
      const notification = {
        type: 'success',
        title: 'Simulation team updated',
        message: `${data.name} has been updated successfully`
      }
      await dispatch('notification/addNotification', notification, { root: true })
    } catch (error) {
      await commit('loading/SET_SIMULATION_TEAM_PROCESSING', false, { root: true })
      const notification = {
        type: 'error',
        title: 'Failed to update simulation team',
        message: error.response.data.message
      }
      await dispatch('notification/addNotification', notification, { root: true })
    }
  },
  async upsertSimulationUsers({ commit, dispatch }, { id, payload }) {
    try {
      await commit('loading/SET_SIMULATION_USER_PROCESSING', true, { root: true })
      const { data } = await simulationService.upsertSimulationUsers(id, payload)
      await commit(types.UPSERT_SIMULATION_USERS_SUCCESS, data.data)
      await commit('loading/SET_SIMULATION_USER_PROCESSING', false, { root: true })
      const notification = {
        type: 'success',
        title: 'Simulation users updated',
        message: 'Simulation users have been updated successfully'
      }
      await dispatch('notification/addNotification', notification, { root: true })
    } catch (error) {
      await commit('loading/SET_SIMULATION_USER_PROCESSING', false, { root: true })
      const notification = {
        type: 'error',
        title: 'Failed to update simulation users',
        message: error.response.data.message
      }
      await dispatch('notification/addNotification', notification, { root: true })
    }
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
