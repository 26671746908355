import apiClient from '@/services'
import axios from 'axios'

let cancelGetVmbObservationData = null

const addVmbItem = (data) => {
  return apiClient.post('/dashboard/survey', data)
}

const getVmbChartData = (simulationId, teamId) => {
  return teamId
    ? apiClient.get(`/dashboard/${simulationId}/graphs?team_id=${teamId}`)
    : apiClient.get(`/dashboard/${simulationId}/graphs`)
}

const getVmbData = (simulationId, teamId) => {
  return teamId
    ? apiClient.get(`/dashboard/${simulationId}/survey?teamId=${teamId}`)
    : apiClient.get(`/dashboard/${simulationId}/survey`)
}

const getVmbObservationData = (simulationId, teamId, typeId, status = 1) => {
  if (cancelGetVmbObservationData) {
    cancelGetVmbObservationData('cancel')
  }

  // Create a new cancel token
  let cancelToken
  const source = axios.CancelToken.source()
  cancelGetVmbObservationData = source.cancel
  cancelToken = source.token

  let queryParams = []

  if (teamId !== null && teamId !== undefined) {
    queryParams.push(`team_id=${teamId}`)
  }

  if (typeId !== null && typeId !== undefined) {
    queryParams.push(`type_id=${typeId}`)
  }

  if (status !== null && status !== undefined) {
    queryParams.push(`status=${status}`)
  }

  const queryString = queryParams.length ? `?${queryParams.join('&')}` : ''

  return apiClient.get(`/dashboard/${simulationId}/observation${queryString}`, { cancelToken })
}

const getVmbObservationTypes = () => {
  return apiClient.get('/dashboard/observation-types')
}

const getVmbWeeklyData = (simulationId, teamId) => {
  return teamId
    ? apiClient.get(`/dashboard/${simulationId}/weekly-data?team_id=${teamId}`)
    : apiClient.get(`/dashboard/${simulationId}/weekly-data`)
}

const getVmbYesterdayData = (weekId) => {
  return apiClient.get(`/dashboard/individual-performance/${weekId}`)
}

const updateVmbObservationStatus = (data) => {
  return apiClient.patch('/dashboard/observation', data)
}

export {
  addVmbItem,
  getVmbChartData,
  getVmbData,
  getVmbObservationData,
  getVmbObservationTypes,
  getVmbWeeklyData,
  getVmbYesterdayData,
  updateVmbObservationStatus
}
