import {
  WHATWENTWELL,
  COMMUNICATIONS,
  FEEDBACK,
  PROBLEMS,
  ACTIONS,
  STATUS,
  WEEKLYDATA,
  YESTERDAYDATA,
  PEOPLE
} from '@/data/dashboardContents'
import * as types from '../mutation-types'
import { dashboardService } from '@/services'
import { initializeVmbModules } from '@/utils/initialization'

const state = {
  vmbData: null,
  vmbObservationData: initializeVmbModules(),
  vmbObservationTypes: [],
  status: null,
  weeklyData: null,
  yesterdayData: null,
  newVmbData: null
}

const getters = {
  vmbData: (state) => state.vmbData,
  vmbObservationData: (state) => state.vmbObservationData,
  vmbObservationTypes: (state) => state.vmbObservationTypes,
  status: (state) => state.status,
  weeklyData: (state) => state.weeklyData,
  yesterdayData: (state) => state.yesterdayData,
  newVmbData: (state) => state.newVmbData
}

const mutations = {
  [types.FETCH_VMBDATA_SUCCESS](state, payload) {
    state.vmbData = payload
  },
  [types.FETCH_VMBDATA_FAILURE](state) {
    state.vmbData = null
  },
  [types.FETCH_VMBOBSERVATIONDATA_SUCCESS](state, payload) {
    const typeId = payload.typeId
    switch (typeId) {
      case 1:
        state.vmbObservationData.problems = payload.problems
        break
      case 2:
        state.vmbObservationData.actions = payload.actions
        break
      case 3:
        state.vmbObservationData['what-went-well'] = payload['what-went-well']
        break
      case 4:
        state.vmbObservationData.communication = payload.communication
        break
      case 5:
        state.vmbObservationData.feedback = payload.feedback
        break
      case 8:
        state.vmbObservationData.people = payload.people
        break
      default:
        state.vmbObservationData.actions = payload.actions
        state.vmbObservationData.communication = payload.communication
        state.vmbObservationData.feedback = payload.feedback
        state.vmbObservationData.people = payload.people
        state.vmbObservationData.problems = payload.problems
        state.vmbObservationData['what-went-well'] = payload['what-went-well']
        break
    }
  },
  [types.FETCH_VMBOBSERVATIONDATA_FAILURE](state, payload) {
    state.vmbObservationData = null
  },
  [types.FETCH_VMBOBSERVATIONTYPES_SUCCESS](state, payload) {
    // lowercase name and replace all spaces with dash
    const types = payload.map((item) => ({
      ...item,
      route: item.name.toLowerCase().replace(/\s/g, '-')
    }))

    state.vmbObservationTypes = types
  },
  [types.UPDATE_VMBOBSERVATIONSTATUS_SUCCESS](state, payload) {
    let observationData = null
    let itemType = payload.type
    switch (itemType) {
      case 1:
        observationData = state.vmbObservationData.problems
        break
      case 2:
        observationData = state.vmbObservationData.actions
        break
      case 3:
        observationData = state.vmbObservationData.whatWentWell
        break
      case 4:
        observationData = state.vmbObservationData.communication
        break
      case 5:
        observationData = state.vmbObservationData.feedback
        break
    }

    let observationItem = observationData.find((item) => item.id === payload.id)
    observationItem.status = payload.status
  },
  [types.UPDATE_VMBOBSERVATIONSTATUS_FAILURE](state) {},
  [types.SAVE_VMBITEMS_SUCCESS](state, payload) {
    state.newVmbData = payload
  },
  [types.SAVE_VMBITEMS_FAILURE](state) {
    state.newVmbData = null
  },
  [types.FETCH_VMBWEEKLYDATA_SUCCESS](state, payload) {
    state.weeklyData = payload
  },
  [types.FETCH_VMBWEEKLYDATA_FAILURE](state) {
    state.weeklyData = null
  },
  [types.FETCH_VMBYESTERDAYDATA_SUCCESS](state, payload) {
    state.yesterdayData = payload
  },
  [types.FETCH_VMBYESTERDAYDATA_FAILURE](state) {
    state.yesterdayData = null
  },
  [types.FETCH_VMBSTATUS_SUCCESS](state, payload) {
    state.status = payload
  },
  [types.FETCH_VMBSTATUS_FAILURE](state) {
    state.status = null
  },
  saveStatus(state, status) {
    state.status = status
  },
  setVmbWeeklyData(state, payload) {
    state.weeklyData = payload
  },
  setYesterdayData(state, payload) {
    state.yesterdayData = payload
  },
  upsertDashboardItems(state, payload) {
    const updateOrInsert = (key, data) => {
      if (Array.isArray(data) && data.length > 0) {
        state.vmbObservationData[key] = state.vmbObservationData[key] || []

        data.forEach((newItem) => {
          let index = 0
          if (key === 'people') {
            index = state.vmbObservationData[key].findIndex(
              (item) => item.user_id === newItem.user_id && item.week_id === newItem.week_id
            )
          } else {
            index = state.vmbObservationData[key].findIndex((item) => item.id === newItem.id)
          }

          if (index !== -1) {
            // Update existing item
            state.vmbObservationData[key].splice(index, 1, newItem)
          } else {
            // Insert new item
            state.vmbObservationData[key].push(newItem)
          }
        })
      }
    }

    updateOrInsert('actions', payload.actions)
    updateOrInsert('communication', payload.communication)
    updateOrInsert('feedback', payload.feedback)
    updateOrInsert('people', payload.people)
    updateOrInsert('problems', payload.problems)
    updateOrInsert('what-went-well', payload['what_went_well'])
  }
}

const actions = {
  async fetchVmbAllData({ commit, dispatch }, { simulationId, teamId }) {
    try {
      const { data } = await dashboardService.getVmbData(simulationId, teamId)
      await commit(types.FETCH_VMBDATA_SUCCESS, data.data)
    } catch (error) {
      const notification = {
        type: 'error',
        title: `Error occurs when fetching VMB data`,
        message: error.response.data.message
      }
      await dispatch('notification/addNotification', notification, { root: true })
    }
  },
  async fetchVmbObservationData(
    { commit, dispatch },
    { simulationId, teamId, typeId, status = 1 }
  ) {
    try {
      await commit('loading/SET_VMB_DATA_LOADING', true, { root: true })
      const { data } = await dashboardService.getVmbObservationData(
        simulationId,
        teamId,
        typeId,
        status
      )
      const payload = {
        actions: data.data.actions,
        communication: data.data.communication,
        feedback: data.data.feedback,
        people: data.data.people,
        problems: data.data.problems,
        'what-went-well': data.data.what_went_well,
        typeId
      }
      await commit(types.FETCH_VMBOBSERVATIONDATA_SUCCESS, payload)
      await commit('loading/SET_VMB_DATA_LOADING', false, { root: true })
    } catch (error) {
      await commit('loading/SET_VMB_DATA_LOADING', false, { root: true })
      // check if the error is due to cancel request
      if (error && error.message === 'cancel') {
        return
      }

      const notification = {
        type: 'error',
        title: `Error occurs when fetching VMB observation data`,
        message: error.response.data.message
      }
      await dispatch('notification/addNotification', notification, { root: true })
    }
  },
  async fetchVmbObservationTypes({ commit, dispatch }) {
    try {
      const { data } = await dashboardService.getVmbObservationTypes()
      await commit(types.FETCH_VMBOBSERVATIONTYPES_SUCCESS, data.data)
    } catch (error) {
      const notification = {
        type: 'error',
        title: `Error occurs when fetching VMB observation types`,
        message: error.response.data.message
      }
      await dispatch('notification/addNotification', notification, { root: true })
    }
  },
  async fetchVmbStatus({ commit }) {
    await commit(types.FETCH_VMBSTATUS_SUCCESS, STATUS)
  },
  async fetchVmbWeeklyData({ commit, dispatch }, { simulationId, teamId }) {
    try {
      await commit('loading/SET_VMB_WEEKLY_DATA_LOADING', true, { root: true })
      const { data } = await dashboardService.getVmbWeeklyData(simulationId, teamId)
      await commit(types.FETCH_VMBWEEKLYDATA_SUCCESS, data.data)
      await commit('loading/SET_VMB_WEEKLY_DATA_LOADING', false, { root: true })
    } catch (error) {
      await commit('loading/SET_VMB_WEEKLY_DATA_LOADING', false, { root: true })
      const notification = {
        type: 'error',
        title: `Error occurs when fetching weekly data`,
        message: error.response.data.message
      }
      await dispatch('notification/addNotification', notification, { root: true })
    }
  },
  async fetchVmbYesterdayData({ commit, dispatch }, weekId) {
    try {
      await commit('loading/SET_VMB_YESTERDAY_DATA_LOADING', true, { root: true })
      const { data } = await dashboardService.getVmbYesterdayData(weekId)
      await commit(types.FETCH_VMBYESTERDAYDATA_SUCCESS, data.data)
      await commit('loading/SET_VMB_YESTERDAY_DATA_LOADING', false, { root: true })
    } catch (error) {
      await commit('loading/SET_VMB_YESTERDAY_DATA_LOADING', false, { root: true })
      const notification = {
        type: 'error',
        title: `Error occurs when fetching yesterday data`,
        message: error.response.data.message
      }
      await dispatch('notification/addNotification', notification, { root: true })
    }
  },
  async submitVmbItems({ commit, dispatch }, newVmbItems) {
    try {
      const { data } = await dashboardService.addVmbItem(newVmbItems)
      await commit(types.SAVE_VMBITEMS_SUCCESS, newVmbItems)
      const notification = {
        type: 'success',
        title: `VMB sumbmitted`,
        message: 'VMB has been successfully submitted'
      }
      await dispatch('notification/addNotification', notification, { root: true })
    } catch (error) {
      const notification = {
        type: 'error',
        title: `Error occurs when submitting VMB survey`,
        message: error.response.data.message
      }
      await dispatch('notification/addNotification', notification, { root: true })
      await commit(types.SAVE_VMBITEMS_FAILURE)
    }
  },
  async updateVmbObservationStatus({ commit }, payload) {
    try {
      await dashboardService.updateVmbObservationStatus(payload)
      // await commit(types.UPDATE_VMBOBSERVATIONSTATUS_SUCCESS, payload)
      // return { status: 'success', message: data.message }
    } catch (e) {
      console.log(e)
      await commit(types.UPDATE_VMBOBSERVATIONSTATUS_FAILURE)
      return { status: 'error', message: e.response.data.message }
    }
  },
  getStatus({ commit }) {
    let status = STATUS
    commit('saveStatus', status)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
