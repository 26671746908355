import apiClient from '@/services'
import axios from 'axios'

let cancelGetUsers = null

const addApprovalCodesFile = (data) => {
  return apiClient.post('/import/approval-codes', data)
}

const addClaimsFile = (data) => {
  return apiClient.post('/import/claims', data)
}

const addTeam = (data) => {
  return apiClient.post('/team/add', data)
}

const addUser = (data) => {
  return apiClient.post('/user/add', data)
}

const deleteTeam = (data) => {
  return apiClient.delete('/team/delete', { data: data })
}

const deleteUser = (data) => {
  return apiClient.delete('/user/delete', { data: data })
}

const getAllTeams = () => {
  return apiClient.get('/teams/all')
}

const getAllTeamWeekDays = (teamId) => {
  return apiClient.get(`/all-week-days/${teamId}`)
}

const getAllTeamWeeks = (teamId) => {
  return apiClient.get(`/weeks/${teamId}`)
}

const getAppConfiguration = () => {
  return apiClient.get('/default-configuration')
}

const getAuditLog = (teamId) => {
  return apiClient.get(`/simulation-audit-log/${teamId}`)
}

const getAuditLogFilters = () => {
  return apiClient.get(`/audit-log-filters`)
}

const getGameFinancials = (teamId) => {
  return apiClient.get(`/team-game-financials/${teamId}`)
}

const getGameSettings = (teamId) => {
  return apiClient.get(`/game-settings/${teamId}`)
}

const getOverallResultsCharts = (simulationId) => {
  return apiClient.get(`/score-centre/overall-results/${simulationId}`)
}

const getPassedScores = () => {
  return apiClient.get('/score-centre/passed')
}

const getProcessedScores = () => {
  return apiClient.get('/score-centre/processed')
}

const getRoles = () => {
  return apiClient.get('/roles')
}

const getRoundBreakdownTable = (simulationId, weekId) => {
  return apiClient.get(`/score-centre/weekly-financials/${simulationId}/${weekId}`)
}

const getTeams = (simulationId) => {
  return apiClient.get(`/teams/${simulationId}`)
}

const getTeamWeekDays = () => {
  return apiClient.get('/team-weekdays')
}

const getTeamWeeks = (teamId) => {
  return apiClient.get(`/team-weeks/${teamId}`)
}

const getTeamWeekWorkAllocations = (teamId, teamWeekId) => {
  return apiClient.get(`/work-allocations/${teamId}/${teamWeekId}`)
}

const getTeamWeekWorkAllocationsStatus = (teamWeekId) => {
  return apiClient.get(`/work-allocation-status/${teamWeekId}`)
}

const getUsers = (search = '', simulationId = null) => {
  if (cancelGetUsers) {
    cancelGetUsers('Operation canceled due to new user request.')
  }

  // Create a new cancel token
  let cancelToken
  const source = axios.CancelToken.source()
  cancelGetUsers = source.cancel
  cancelToken = source.token

  return simulationId
    ? apiClient.get(`/users?search=${search}&simulation_id=${simulationId}`, { cancelToken })
    : apiClient.get(`/users?search=${search}`, { cancelToken })

  // return apiClient.get(`/users?search=${search}`, { cancelToken })
}

const getWeeklyResultsCharts = (simulationId) => {
  return apiClient.get(`/score-centre/weekly-results/${simulationId}`)
}

const getWorkAllocations = () => {
  return apiClient.get('/work')
}

const resetSimulationData = () => {
  return apiClient.get('/reset-data')
}

const resetUserData = () => {
  return apiClient.get('/reset-data/user')
}

const rollbackTeamWeekDay = (teamId, fromWeekDayId, toWeekDayId) => {
  return apiClient.post(`/rollback/${teamId}/${fromWeekDayId}/${toWeekDayId}`)
}

const triggerGame = (id) => {
  return apiClient.post('/control-game-play', id)
}

const unblockUser = (data) => {
  return apiClient.post('/user/unblock', data)
}

const updateGameFinancials = (data) => {
  return apiClient.post('/team-game-financials', data)
}

const updateGameSettings = (data) => {
  return apiClient.post('/game-settings', data)
}

const updateTeam = (data) => {
  return apiClient.post('/team/update', data)
}

const updateTeamWeek = (data) => {
  return apiClient.post('/close-team-week', data)
}

const updateTeamWeekday = (data) => {
  return apiClient.post('/team-weekday', data)
}

const updateTeamWeekSettings = (data) => {
  return apiClient.post('/team-week', data)
}

const updateTeamUserFile = (data) => {
  return apiClient.post('/import/users', data)
}

const updateUser = (data) => {
  return apiClient.post('/user/update', data)
}

const updateWorkAllocations = (data) => {
  return apiClient.post('/work-allocation', data)
}

export {
  addClaimsFile,
  addApprovalCodesFile,
  addTeam,
  addUser,
  deleteTeam,
  deleteUser,
  getAllTeams,
  getAllTeamWeeks,
  getAllTeamWeekDays,
  getAppConfiguration,
  getAuditLog,
  getAuditLogFilters,
  getGameFinancials,
  getGameSettings,
  getOverallResultsCharts,
  getPassedScores,
  getProcessedScores,
  getRoles,
  getRoundBreakdownTable,
  getTeams,
  getTeamWeeks,
  getTeamWeekDays,
  getTeamWeekWorkAllocations,
  getTeamWeekWorkAllocationsStatus,
  getUsers,
  getWeeklyResultsCharts,
  getWorkAllocations,
  resetSimulationData,
  resetUserData,
  rollbackTeamWeekDay,
  triggerGame,
  unblockUser,
  updateGameFinancials,
  updateGameSettings,
  updateTeam,
  updateTeamUserFile,
  updateTeamWeek,
  updateTeamWeekday,
  updateTeamWeekSettings,
  updateUser,
  updateWorkAllocations
}
